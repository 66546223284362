import React, { useState } from 'react';
import { Modal, Button, Input } from 'semantic-ui-react';

const ModalDatePicker = ({ text, callbackConfirm, callbackCancel }) => {
    const [date, setDate] = useState('');

    return (
        <Modal open={true} onClose={callbackCancel}>
            <Modal.Header>{text}</Modal.Header>
            <Modal.Content>
                <Input
                    type="date"
                    value={date}
                    onChange={e => setDate(e.target.value)}
                    placeholder="Select a date"
                />
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={callbackCancel}>Cancel</Button>
                <Button
                    primary
                    disabled={!date}
                    onClick={() => callbackConfirm(new Date(date))}
                >
                    Confirm
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default ModalDatePicker;
