import React, { useState, useEffect } from 'react'
import { Context } from '../../Context'
import { Header, Container, Loader, Message } from 'semantic-ui-react'
import { getInstallations } from '../../api'
import InstallationsTable from './InstallationsTable'

function UserInstallations() {
    const { game, player } = React.useContext(Context)
    const [isLoading, setIsLoading] = useState(false)
    const [installations, setInstallations] = useState([])
    const [error, setError] = useState(null) // State to hold error messages

    useEffect(() => {
        setIsLoading(true)
        setError(null) // Reset error state on new fetch
        getInstallations(game, player.guid, (err, data) => {
            // console.log('****getInstallations error', err)
            // console.log('****getInstallations data', data)

            setIsLoading(false)
            if (err) {
                setError(err)
            } else {
                setInstallations(data)
            }
        })
    }, [])

    if (!player) return null

    return (
        <Container>
            <Header>User Installations</Header>
            {isLoading ? (
                <Loader active inline="centered" />
            ) : error ? (
                <Message negative>
                    <Message.Header>Error</Message.Header>
                    <p>{error}</p>
                </Message>
            ) : (
                <div>
                    <InstallationsTable installations={installations} />
                </div>
            )}
        </Container>
    )
}

export default UserInstallations
