import React from 'react'
import { Grid, Button, Dropdown } from 'semantic-ui-react'

import { useAuthorization } from '../Contexts/AuthorizationContext'

const Drop = ({ title, handler, type }) => (
	<Dropdown as={Button} fluid text={title}>
		<Dropdown.Menu>
			<Dropdown.Item
				text="Give Ban"
				onClick={() => {
					handler(type, 'override_policy_true')
				}}
			/>
			<Dropdown.Item
				text="Remove Ban"
				onClick={() => {
					handler(type, 'override_policy_false')
				}}
			/>
			<Dropdown.Item
				text="Follow Policy"
				onClick={() => {
					handler(type, 'follow_policy')
				}}
			/>
		</Dropdown.Menu>
	</Dropdown>
)

const Commands = ({
	onDeleteHandler,
	onNoAdsHandler,
	onBanHandler,
	onSoftBanHandler,
	onClearCache,
	onMakeModeratorHandler,
	noAds,
	isBanned,
	isModerator, // riferito al player
}) => {
	// rifertito alla persona loggata su simon
	const { isUserAdmin } = useAuthorization()

	// console.log('player isModerator', isModerator)
	// console.log('user isUserAdmin', user.authorization, isUserAdmin)

	return (
		<Grid columns={2}>
			<Grid.Column>
				<Button fluid onClick={onDeleteHandler}>
					Delete Account
				</Button>
			</Grid.Column>
			<Grid.Column>
				<Button fluid onClick={onNoAdsHandler}>
					{noAds ? 'Restore Ads' : 'Remove Ads'}
				</Button>
			</Grid.Column>
			<Grid.Column>
				<Button fluid onClick={onBanHandler}>
					{/* {isBanned ? 'Remove Hard Ban' : 'Give Hard Ban'} */}
					Set Hard Ban Date
				</Button>
			</Grid.Column>
			<Grid.Column>
				<Drop title="Chat Ban" handler={onSoftBanHandler} type="chat" />
			</Grid.Column>
			<Grid.Column>
				<Drop title="Nickname Ban" handler={onSoftBanHandler} type="nickname" />
			</Grid.Column>
			<Grid.Column>
				<Drop title="Picture Ban" handler={onSoftBanHandler} type="picture" />
			</Grid.Column>
			<Grid.Column>
				<Button fluid onClick={onClearCache}>
					Clear Cache
				</Button>
			</Grid.Column>
			{isUserAdmin && (
				<Grid.Column>
					<Button fluid onClick={onMakeModeratorHandler}>
						{isModerator ? 'Unmake Moderator' : 'Make Moderator'}
					</Button>
				</Grid.Column>
			)}
		</Grid>
	)
}

export default Commands
