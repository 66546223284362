import React, { useState, useContext } from 'react'
import { Divider } from 'semantic-ui-react'
import { withRouter } from 'react-router'

import UserInfo from './UserInfo'
import Commands from './Commands'
import Loader from '../Loader'

import { Context } from '../Context'
import { useAuthorization } from '../Contexts/AuthorizationContext'

import {
    deleteUser,
    setUserNoAds,
    setUserBan,
    setUserSoftBanOverride,
    clearCache,
    setUserAuthorization,
} from '../api'
import ModalConfirm from '../modals/ModalConfirm'
import ModalOk from '../modals/ModalOk'
import ModalDatePicker from '../modals/ModalDatePicker'

const UserInfoAndCommands = ({ history }) => {
    const { player, setPlayer, game } = useContext(Context)
    let { noAds, isBanned } = player
    const isPlayerModerator = player.authorization === 'moderator'

    let { user } = useAuthorization()

    const [loading, setLoading] = useState(false)
    const [messageDelete, setMessageDelete] = useState(null)
    const [messageClearCache, setMessageClearCache] = useState(null)
    const [messageNoAds, setMessageNoAds] = useState(null)
    const [messageBan, setMessageBan] = useState(null)
    const [messageGeneral, setMessageGeneral] = useState(null)
    const [shouldConfirmDelete, setShouldConfirmDelete] = useState(false)
    const [shouldConfirmClearCache, setShouldConfirmClearCache] = useState(false)
    const [shouldConfirmNoAds, setShouldConfirmNoAds] = useState(false)
    const [shouldConfirmBan, setShouldConfirmBan] = useState(false)
    const [shouldConfirmMakeModerator, setShouldConfirmMakeModerator] = useState(false)

    const [shouldShowDatePicker, setShouldShowDatePicker] = useState(false)
    // const [selectedBanDate, setSelectedBanDate] = useState(null)

    // uso un solo sistema per i softBan
    const [shouldConfirmSoftBan, setShouldConfirmSoftBan] = useState({
        type: null,
        hasModal: false,
        override: null,
    })

    if (process.env.NODE_ENV === 'development') {
        // console.log('Nessun utente loggato (development) verrà creato uno user di sviluppo')
        user = {}
        user.guid = 'development-guid'
        user.authorization = 'admin'
    }
    // console.log('PLAYER', player)

    const onSoftBanHandler = (type, override) => {
        setShouldConfirmSoftBan({
            type: type,
            hasModal: true,
            override: override,
        })
    }

    const onDeleteHandler = () => {
        setShouldConfirmDelete(true)
    }

    const onClearCacheHandler = () => {
        setShouldConfirmClearCache(true)
    }

    const onMakeModeratorHandler = () => {
        setShouldConfirmMakeModerator(true)
    }

    const onNoAdsHandler = () => {
        setShouldConfirmNoAds(true)
    }

    const onBanHandler = () => {
        // setShouldConfirmBan(true)
        setShouldShowDatePicker(true)
    }

    if (!game) return null
    if (!player) return null

    if (loading) return <Loader />

    if (shouldConfirmSoftBan.hasModal)
        return (
            <ModalConfirm
                text="Sure?"
                callbackYes={() => {
                    setLoading(true)
                    // dont show modal again
                    setShouldConfirmSoftBan({
                        type: shouldConfirmSoftBan.type,
                        hasModal: false,
                        override: shouldConfirmSoftBan.override,
                    })
                    // api call
                    setUserSoftBanOverride(
                        user.guid,
                        user.authorization,
                        game,
                        player.guid,
                        shouldConfirmSoftBan.type,
                        shouldConfirmSoftBan.override,
                        (err, updatedPlayer) => {
                            setLoading(false)
                            if (err) return setMessageGeneral(err)
                            setPlayer(updatedPlayer)
                        }
                    )
                }}
                callbackNo={() => {
                    setShouldConfirmSoftBan({
                        type: shouldConfirmSoftBan.type,
                        hasModal: false,
                        override: shouldConfirmSoftBan.override,
                    })
                }}
            />
        )

    if (shouldConfirmDelete)
        return (
            <ModalConfirm
                text="Sure?"
                callbackYes={() => {
                    setLoading(true)
                    setShouldConfirmDelete(false)
                    deleteUser(user.guid, user.authorization, game, player.guid, err => {
                        setLoading(false)
                        if (err) return setMessageDelete(err)
                        setMessageDelete('OK!')
                    })
                }}
                callbackNo={() => {
                    setShouldConfirmDelete(false)
                }}
            />
        )
    if (shouldConfirmClearCache)
        return (
            <ModalConfirm
                text="Sure?"
                callbackYes={() => {
                    setLoading(true)
                    setShouldConfirmClearCache(false)

                    // console.log('======>', user.guid, user.authorization)
                    clearCache(user.guid, user.authorization, game, player.guid, err => {
                        setLoading(false)
                        if (err) return setMessageClearCache(err)
                        setMessageClearCache('OK!')
                    })
                }}
                callbackNo={() => {
                    setShouldConfirmClearCache(false)
                }}
            />
        )

    if (shouldConfirmMakeModerator)
        return (
            <ModalConfirm
                text="Sure?"
                callbackYes={() => {
                    setLoading(true)
                    setShouldConfirmMakeModerator(false)
                    // switch tra moderator e user (è un toggle)
                    const value = player.authorization === 'moderator' ? 'user' : 'moderator'
                    // console.log(value)
                    setUserAuthorization(
                        user.guid,
                        user.authorization,
                        game,
                        player.guid,
                        value,
                        (err, updatedPlayer) => {
                            setLoading(false)
                            if (err) return setMessageGeneral(err)
                            // console.log('updatedPlayer',updatedPlayer)
                            setPlayer(updatedPlayer)
                            setMessageGeneral('OK!')
                        }
                    )
                }}
                callbackNo={() => {
                    setShouldConfirmMakeModerator(false)
                }}
            />
        )

    if (shouldConfirmNoAds)
        return (
            <ModalConfirm
                text="Sure?"
                callbackYes={() => {
                    setLoading(true)
                    setShouldConfirmNoAds(false)
                    noAds = !noAds
                    setUserNoAds(user.guid, user.authorization, game, player.guid, noAds, err => {
                        setLoading(false)
                        if (err) return setMessageNoAds(err)
                        setMessageNoAds('OK!')
                    })
                }}
                callbackNo={() => {
                    setShouldConfirmNoAds(false)
                }}
            />
        )

    if (shouldShowDatePicker)
        return (
            <ModalDatePicker
                text="Select ban expiration date"
                callbackConfirm={expiresAt => {
                    setLoading(true)
                    setShouldShowDatePicker(false)
                    // setSelectedBanDate(expiresAt)
                    setUserBan(user.guid, user.authorization, game, player.guid, expiresAt, err => {
                        setLoading(false)
                        if (err) return setMessageBan(err)
                        setMessageBan('Ban date set successfully! Reload the page.')
                    })
                }}
                callbackCancel={() => {
                    setShouldShowDatePicker(false)
                }}
            />
        )

    // if (shouldConfirmBan)
    //     return (
    //         <ModalConfirm
    //             text="Sure?"
    //             callbackYes={() => {
    //                 setLoading(true)
    //                 setShouldConfirmBan(false)
    //                 isBanned = !isBanned
    //                 setUserBan(user.guid, user.authorization, game, player.guid, isBanned, err => {
    //                     setLoading(false)
    //                     if (err) return setMessageBan(err)
    //                     setMessageBan('OK!')
    //                 })
    //             }}
    //             callbackNo={() => {
    //                 setShouldConfirmBan(false)
    //             }}
    //         />
    //     )

    if (messageDelete)
        return (
            <ModalOk
                title="Result"
                text={messageDelete}
                callbackOk={() => {
                    setPlayer(null)
                    setMessageDelete(null)
                    history.push('/')
                }}
            />
        )

    if (messageClearCache)
        return (
            <ModalOk
                title="Result"
                text={messageClearCache}
                callbackOk={() => {
                    // setPlayer(null)
                    setMessageClearCache(null)
                    // history.push('/')
                }}
            />
        )

    if (messageNoAds)
        return (
            <ModalOk
                title="Result"
                text={messageNoAds}
                callbackOk={() => {
                    setMessageNoAds(null)
                    player.noAds = !noAds
                    setPlayer(player)
                }}
            />
        )
    if (messageBan)
        return (
            <ModalOk
                title="Result"
                text={messageBan}
                callbackOk={() => {
                    setMessageBan(null)
                    player.isBanned = !isBanned
                    setPlayer(player)
                }}
            />
        )

    if (messageGeneral)
        return (
            <ModalOk
                title="Result"
                text={messageGeneral}
                callbackOk={() => {
                    setMessageGeneral(null)
                }}
            />
        )

    return (
        <div>
            {/*<Divider />*/}
            <UserInfo />
            <Divider />
            <Commands
                noAds={noAds}
                isBanned={isBanned}
                isModerator={isPlayerModerator}
                onDeleteHandler={onDeleteHandler}
                onNoAdsHandler={onNoAdsHandler}
                onBanHandler={onBanHandler}
                onSoftBanHandler={onSoftBanHandler}
                onClearCache={onClearCacheHandler}
                onMakeModeratorHandler={onMakeModeratorHandler}
            />
        </div>
    )
}

export default withRouter(UserInfoAndCommands)
